<template>
  <div class="main">
    <div class="row mx-4 my-4">
      <EmployeeCard></EmployeeCard>
      <div class="col-xl-9 col-lg-8 ps-4 userSettings">
        <ProfileProgress></ProfileProgress>
        <div class="mt-4 settingsBody rounded">
          <div class="pt-3 pb-0 ps-4">
            <strong>Vinculación Laboral</strong>
          </div>
          <hr />
          <div>
            <form class="settingForm" @submit.prevent="sendBasic">
              <div class="mb-3">
                <label for="startInTheCompany" class="form-label"
                  >Fecha de Ingreso en la Empresa</label
                >
                <input
                  type="date"
                  class="form-control"
                  name="startInTheCompany"
                  v-model="startInTheCompany"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="workArea" class="form-label">Área de Trabajo</label>
                <select
                  class="form-select text-capitalize"
                  name="workArea"
                  v-model="workArea"
                  required
                >
                  <option
                    v-for="workArea in workAreaList"
                    v-bind:value="workArea._id"
                    :key="workArea._id"
                    >{{ workArea.name }}</option
                  >
                </select>
              </div>
              <div class="mb-3">
                <label for="typeOfContract" class="form-label"
                  >Tipo de Contrato</label
                >
                <select
                  class="form-select text-capitalize"
                  name="typeOfContract"
                  v-model="typeOfContract"
                  required
                >
                  <option
                    v-for="typeOfContract in typeOfContractList"
                    v-bind:value="typeOfContract"
                    :key="typeOfContract"
                    >{{
                      typeOfContract
                        .toLowerCase()
                        .split("_")
                        .join(" ")
                    }}</option
                  >
                </select>
              </div>
              <div class="mb-3">
                <label for="startAtWork" class="form-label"
                  >Fecha de Inicio en el Cargo</label
                >
                <input
                  type="date"
                  class="form-control"
                  name="startAtWork"
                  v-model="startAtWork"
                  required
                />
              </div>
              <div class="mb-3">
                <div class="d-flex justify-content-between">
                  <label for="salary" class="form-label">Salario</label>
                  <div>
                    <label for="minimumSalary" class="form-label me-2"
                      >¿Salario Mínimo?</label
                    >
                    <input
                      @click="getMinimumSalary"
                      v-model="minimumSalary"
                      class="form-check-input"
                      type="checkbox"
                      name="minimumSalary"
                    />
                  </div>
                </div>
                <div class="input-group">
                  <span class="input-group-text">$</span>
                  <input
                    type="number"
                    class="form-control"
                    name="salary"
                    step=".01"
                    :min="minSalary"
                    :max="maxSalary"
                    v-model="salary"
                    :disabled="minimumSalary"
                    required
                  />
                </div>
              </div>
              <div class="mb-3">
                <label for="workshift" class="form-label"
                  >Tipo de Turno Laboral</label
                >
                <select
                  class="form-select text-capitalize"
                  name="workshift"
                  v-model="workshift"
                  required
                >
                  <option
                    v-for="workshift in workshiftList"
                    v-bind:value="workshift"
                    :key="workshift"
                    >{{
                      workshift
                        .toLowerCase()
                        .split("_")
                        .join(" ")
                    }}</option
                  >
                </select>
              </div>
              <div class="mb-3">
                <label for="transport" class="form-label"
                  >Medio de Transporte</label
                >
                <select
                  class="form-select text-capitalize"
                  name="transport"
                  v-model="transport"
                  required
                >
                  <option
                    v-for="transport in transportList"
                    v-bind:value="transport"
                    :key="transport"
                    >{{
                      transport
                        .toLowerCase()
                        .split("_")
                        .join(" ")
                    }}</option
                  >
                </select>
              </div>
              <div class="mb-3">
                <label for="occupation" class="form-label">Ocupación</label>
                <input
                  type="text"
                  class="form-control"
                  name="occupation"
                  minlength="1"
                  :maxlength="maxOccupation"
                  v-model="occupation"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="profession" class="form-label">Profesión</label>
                <input
                  type="text"
                  class="form-control"
                  name="profession"
                  minlength="1"
                  :maxlength="maxProfession"
                  v-model="profession"
                  required
                />
              </div>
              <button type="submit mb-3" class="btn btn-primary shadow-primary">
                Actualizar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Legal></Legal>
  </div>
</template>

<style lang="css" scoped>
.settingsBody {
  background-color: var(--secondary-bg);
}
.settingForm {
  margin: auto;
  padding-bottom: 20px;
  width: 50%;
  min-width: 280px;
}
@media only screen and (max-width: 990px) {
  .userSettings {
    padding: 0 !important;
  }
  .userSettings {
    margin-block: 30px;
  }
}
</style>

<script>
import Legal from "@/components/layout/Legal";
import EmployeeCard from "@/views/employee/EmployeeCard";
import ProfileProgress from "@/views/employee/ProfileProgress";

import Storage from "@/modules/storage";
import Swal from "sweetalert2";

export default {
  components: { Legal, EmployeeCard, ProfileProgress },
  name: "Employee Profile Edit Labor",
  data() {
    return {
      startInTheCompany: "",
      workArea: "",
      workAreaList: [],
      typeOfContract: "",
      typeOfContractList: [],
      startAtWork: "",
      salary: "",
      minSalary: 0,
      maxSalary: 1,
      minimumSalary: false,
      workshift: "",
      workshiftList: [],
      transport: "",
      transportList: [],
      occupation: "",
      maxOccupation: 1,
      profession: "",
      maxProfession: 1
    };
  },
  beforeCreate() {
    const store = new Storage();

    store.getData("employee", this.$route.params._id).then(employeeData => {
      store.getData("branch", employeeData.branch).then(branchData => {
        store.getData("city", branchData.city).then(cityData => {
          localStorage.setItem(
            "currentLocation",
            "Empleados / Perfiles / " +
              cityData.name +
              " / " +
              employeeData.name +
              " / Vinculación Laboral"
          );
        });
      });
    });
  },
  mounted() {
    const store = new Storage();

    this.workAreaList = [];

    store.getData("employee", this.$route.params._id).then(employee => {
      store.getData("branch", employee.branch).then(branchData => {
        store.getData("workArea").then(workAreaData => {
          workAreaData.sort(function(a, b) {
            return a.name.localeCompare(b.name);
          });
          workAreaData.forEach(workArea => {
            if (workArea.branch === branchData._id) {
              this.workAreaList.push(workArea);
            }
          });
        });
      });
      this.startInTheCompany = employee.startInTheCompany;
      this.workArea = employee.workArea;
      this.typeOfContract = employee.typeOfContract;
      this.startAtWork = employee.startAtWork;
      this.salary = employee.salary;
      this.minimumSalary = employee.minimumSalary;
      this.workshift = employee.workshift;
      this.transport = employee.transport;
      this.occupation = employee.occupation;
      this.profession = employee.profession;

      if (this.startInTheCompany) {
        const d = new Date(this.startInTheCompany);
        const year = d.getFullYear();
        let month = d.getMonth() + 1;
        if (month < 10) {
          month = "0" + month.toString();
        }
        let day = d.getDate();
        if (day < 10) {
          day = "0" + day.toString();
        }
        this.startInTheCompany = year + "-" + month + "-" + day;
      }
      if (this.startAtWork) {
        const d = new Date(this.startAtWork);
        const year = d.getFullYear();
        let month = d.getMonth() + 1;
        if (month < 10) {
          month = "0" + month.toString();
        }
        let day = d.getDate();
        if (day < 10) {
          day = "0" + day.toString();
        }
        this.startAtWork = year + "-" + month + "-" + day;
      }
    });
    store.getData("schema").then(schemaList => {
      schemaList.forEach(schema => {
        if (schema.store === "employee") {
          this.typeOfContractList = schema.data.typeOfContract.enum;
          this.workshiftList = schema.data.workshift.enum;
          this.transportList = schema.data.transport.enum;
          this.minSalary = schema.data.salary.min;
          this.maxSalary = schema.data.salary.max;
          this.maxOccupation = schema.data.occupation.max;
          this.maxProfession = schema.data.profession.max;
        }
      });
    });
  },
  watch: {
    minimumSalary: function(data) {
      if (data) {
        const store = new Storage();
        store.getData("country").then(country => {
          this.salary = country[0].minimumSalary;
        });
      }
    }
  },
  methods: {
    sendBasic() {
      Swal.fire({
        icon: "warning",
        title: "Actualizando información",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = Swal.getTimerLeft();
              }
            }
          }, 100);
        }
      });
      const store = new Storage();

      let startInTheCompany = this.startInTheCompany;
      let year = startInTheCompany.split("-")[0];
      let month = startInTheCompany.split("-")[1] - 1;
      if (month < 10) {
        month = "0" + month.toString();
      }
      let day = startInTheCompany.split("-")[2];
      day++;
      if (day < 10) {
        day = "0" + day.toString();
      }
      startInTheCompany = new Date(Date.UTC(year, month, day));
      startInTheCompany = startInTheCompany.getTime();

      let startAtWork = this.startAtWork;
      year = startAtWork.split("-")[0];
      month = startAtWork.split("-")[1] - 1;
      if (month < 10) {
        month = "0" + month.toString();
      }
      day = startAtWork.split("-")[2];
      day++;
      if (day < 10) {
        day = "0" + day.toString();
      }
      startAtWork = new Date(Date.UTC(year, month, day));
      startAtWork = startAtWork.getTime();

      const data = {
        _id: this.$route.params._id,
        startInTheCompany: startInTheCompany,
        workArea: this.workArea,
        typeOfContract: this.typeOfContract,
        startAtWork: startAtWork,
        salary: this.salary,
        minimumSalary: this.minimumSalary,
        workshift: this.workshift,
        transport: this.transport,
        occupation: this.occupation,
        profession: this.profession
      };
      store
        .updateData("employee", [data], true, "optima")
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Datos Actualizados",
            text: "Todos los datos han sido sincronizados"
          }).then(() => {
            this.$router.go();
          });
        })
        .catch(error => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Error al actualizar los datos",
            text:
              "Los datos han sido guardados en su computador o celular, una vez se restablesca la conexión, los datos se sincronizarán con el servidor"
          }).then(result => {
            this.$router.go();
          });
        });
    }
  }
};
</script>
